import React from 'react';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Box, Grid, Typography, Link } from '@material-ui/core';

import { clearForm } from '../../helpers/clearForm';

import { FormContext } from '../../contexts/FormContext';
import styleVars from '../../styleVars';
import Icon from 'react-icons-kit';
import { ic_forum as icForum } from 'react-icons-kit/md';
import { ic_print as icPrint } from 'react-icons-kit/md';
import { CondensedReview } from './CondensedReview';
import moment from 'moment';
const CompletedForm = () => {
  const navigate = useNavigate();
  const { formValues, setFormValues } = useContext(FormContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (Number(formValues?.formStep) < 6) {
      setFormValues({ ...formValues, formStep: 6 });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const dateOfInjury = moment(formValues?.dateOfInjury).format("YYYY-MM-DD");
  const pdfUrl = `${
    process.env.REACT_APP_REFERRAL_BACKEND
  }/public/referral/pdf?referral_id=${formValues?.referralId}&claim_number=${
    formValues?.claimNumber
  }&claim_date_of_injury=${dateOfInjury}`;

  return (
    <>
      <Grid item xs={12}>
        <Box style={{ width: '925px' }}></Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4" style={{ color: '#3470A1' }}>
          We've received your referral
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        <Box height="28px"></Box>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          style={{ fontSize: styleVars.fontSizeLarge, fontWeight: 600 }}
        >
          Thanks for choosing Bardavon
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        <Box height="28px"></Box>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          style={{ fontSize: styleVars.fontSizeLarge, fontWeight: 500 }}
        >
          Here's what to expect next
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        <Box height="30px"></Box>
      </Grid>
      <Grid item xs={12} style={{ marginTop: '0px' }}>
        <ul
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            margin: 0,
          }}
        >
          <Typography variant="body2" component="li">
            A confirmation message will be sent to you
          </Typography>

          <Typography variant="body2" component="li">
            If we need any further information, we'll contact you shortly.
          </Typography>

          <Typography variant="body2" component="li">
            You'll get an email confirming when we've placed this patient for
            his/her first visit
          </Typography>

          <Typography variant="body2" component="li">
            If you have any problems or questions, call us at (913) 236-1020
          </Typography>
        </ul>
      </Grid>
      <Grid container spacing={2}>
        <Grid container spacing={2}>
          <Box height="60px"></Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                onClick={() => {
                  navigate('/');
                }}
              >
                <Typography variant="button">Done</Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                size="medium"
                onClick={() => {
                  clearForm(formValues, setFormValues, navigate, true);
                }}
              >
                <Typography variant="button">Add Another</Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Link
                href={pdfUrl}
                target="_blank"
                component="a"
                style={{
                  cursor: 'pointer',
                  color: styleVars.colorThemeBlue,
                  fontSize: '14px',
                  fontWeight: 'normal',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  margin: '15px 50px 0px 8px',
                  whiteSpace: 'nowrap',
                }}
              >
                <Icon icon={icPrint} size={22} style={{ marginRight: '8px' }} />
                Print a copy for your records
              </Link>

              <Link
                href={
                  'https://forms.office.com/pages/responsepage.aspx?id=3fh1FNGx7U6LtFvU1kutXx8CFGX-oFlGsu4lVVxF-uJUOFRYUDVWWTlGRkpMOFlBVkxKV0hNQ09KNi4u'
                }
                target="_blank"
                style={{
                  cursor: 'pointer',
                  color: styleVars.colorThemeBlue,
                  fontSize: '14px',
                  fontWeight: 'normal',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: '15px',
                  whiteSpace: 'nowrap',
                }}
              >
                <Icon icon={icForum} size={22} style={{ marginRight: '8px' }} />
                Give us feedback on this form
              </Link>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box style={{ height: '20px' }}></Box>
          <CondensedReview formValues={formValues} completedPage />
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              href={pdfUrl}
              target="_blank"
              style={{ marginTop: '20px' }}
            >
              <Typography variant="button">Print</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CompletedForm;
