import { Typography } from '@material-ui/core';

import { NOVALUE } from '../constants/textConstants';
import { ReferralFormSchema } from '../types/ReferralFormSchema';
import { BodyPart, File } from '../types/ReferralFormSchema';
import {
  formatDate,
  getContactPreference,
  getSurgicalFormattedNames,
  getSelectNameProperty,
  getReferrerName,
  getReferrerPhone,
  getReferrerEmail,
  getCaseType,
  getPatientGender,
  getTreatingPhysician,
  formatVisitText,
  formatAddress,
  getUserTypeLabel,
} from '../helpers/dataFormatters';

import languageChoices from '../constants/languageChoices';
import states from '../constants/states';

import ethnicityChoices from '../constants/ethnicityChoices';
import patientNameSuffixChoices from '../constants/suffixChoices';
import raceChoices from '../constants/raceChoices';
import { MedicalDiagnosisType } from '../types/RequiredInfoTypes';

export type FormValue = {
  name: string;
  value: any;
};
export type FormattedValuesGrouping = {
  yourInfo: FormValue[];
  referralInfo: FormValue[];
  authorizations: FormValue[];
  stakeholders: FormValue[];
  patient: FormValue[];
  other: FormValue[];
};

export const parseAndGroupFormData = (formValues: ReferralFormSchema) => {
  const modifiedData: FormattedValuesGrouping = {
    yourInfo: [],
    referralInfo: [],
    authorizations: [],
    stakeholders: [],
    patient: [],
    other: [],
  };
  // Building Your info section
  modifiedData.yourInfo.push({
    name: 'Role',
    value: getUserTypeLabel(formValues?.userType),
  });
  modifiedData.yourInfo.push({
    name: 'Name',
    value: getReferrerName(formValues),
  });
  modifiedData.yourInfo.push({
    name: 'Email',
    value: getReferrerEmail(formValues),
  });
  modifiedData.yourInfo.push({
    name: 'Preferred contact',
    value: getContactPreference(
      formValues?.userPhonePreferred,
      formValues?.userEmailPreferred
    ),
  });
  modifiedData.yourInfo.push({
    name: 'Phone',
    value: getReferrerPhone(formValues),
  });
  modifiedData.yourInfo.push({
    name: 'Files',
    value: formValues?.uploadFiles?.length
      ? formValues?.uploadFiles.map((f: File, i: number) => {
          // Changing this breaks compatibility with the current review page
          return (
            <Typography
              component="div"
              variant="body1"
              style={{ fontSize: '14px' }}
              key={`${f?.name}${i}`}
            >
              {f?.name}({f?.category}){' '}
              {Number(formValues?.uploadFiles?.length) > 0 &&
                i !== Number(formValues?.uploadFiles?.length) - 1 &&
                '|'}{' '}
            </Typography>
          );
        })
      : null,
  });

  // Building referral info section
  modifiedData.referralInfo.push({
    name: 'Patient name',
    value:
      formValues?.patientFirstName && formValues?.patientLastName
        ? `${formValues?.patientFirstName} ${formValues?.patientLastName}`
        : NOVALUE,
  });
  modifiedData.referralInfo.push({
    name: 'Birthday',
    value: formatDate(formValues?.patientBirthday),
  });
  modifiedData.referralInfo.push({
    name: 'Gender',
    value: getPatientGender(formValues?.patientSex),
  });
  modifiedData.referralInfo.push({
    name: 'Phone number',
    value: formValues?.patientPhoneNumber,
  });
  modifiedData.referralInfo.push({
    name: 'Primary language',
    value: getSelectNameProperty(
      formValues?.patientPrimaryLanguage,
      languageChoices
    ),
  });
  // Forcing this to be null if undefined
  modifiedData.referralInfo.push({
    name: 'Interpeter authorized',
    value: formValues?.interpreterAuthorized
      ? 'Yes'
      : formValues?.interpreterAuthorized === undefined
      ? null
      : 'No',
  });
  // Forcing this to be null if undefined
  modifiedData.referralInfo.push({
    name: 'Transportation authorized',
    value: formValues?.transportationAuthorized
      ? 'Yes'
      : formValues?.transportationAuthorized === undefined
      ? null
      : 'No',
  });
  modifiedData.referralInfo.push({
    name: 'Claim number',
    value: formValues?.claimNumber,
  });
  modifiedData.referralInfo.push({
    name: 'State of Jurisdiction',
    value: getSelectNameProperty(formValues?.stateOfJury, states),
  });
  modifiedData.referralInfo.push({
    name: 'Injury date',
    value: formatDate(formValues?.dateOfInjury),
  });
  modifiedData.referralInfo.push({
    name: 'Treating physician',
    value: getTreatingPhysician(formValues),
  });
  modifiedData.referralInfo.push({
    name: 'Case type',
    value: getCaseType(formValues?.caseType),
  });
  modifiedData.referralInfo.push({
    name: 'Specialty',
    value: formValues?.specialty,
  });
  modifiedData.referralInfo.push({
    name: 'Diagnosis/ICD-10',
    value: formValues?.medicalDiagnosis?.length
      ? formValues?.medicalDiagnosis.map(
          (m: MedicalDiagnosisType, i: number) => {
            return <div key={m.combined}>{m?.combined}</div>;
          }
        )
      : null,
  });
  modifiedData.referralInfo.push({
    name: 'Body part',
    value: formValues?.bodyPart?.length
      ? formValues?.bodyPart?.map((b: BodyPart, i: number) => {
          return <div key={b.BodyPartName}>{b?.BodyPartName}</div>;
        })
      : null,
  });
  modifiedData.referralInfo.push({
    name: 'Surgical',
    value: getSurgicalFormattedNames(formValues?.surgical),
  });
  modifiedData.referralInfo.push({
    name: 'Surgery Date',
    value: formatDate(formValues?.dateOfSurgery),
  });
  modifiedData.referralInfo.push({
    name: 'Employer',
    value: formValues?.employerSelected?.label || formValues?.employerCompany,
  });
  modifiedData.referralInfo.push({
    name: 'Job title',
    value: formValues?.jobTitle,
  });

  // Building authorizations
  modifiedData.authorizations.push({
    name: 'Script date',
    value: formatDate(formValues?.authScriptDate),
  });
  modifiedData.authorizations.push({
    name: 'Script expiration date',
    value: formatDate(formValues?.authScriptEndDate),
  });
  modifiedData.authorizations.push({
    name: 'Authorized visits',
    value: formatVisitText(
      formValues?.authAuthorizedVisits,
      formValues?.authVisitTimes,
      formValues?.authVisitWeeks
    ),
  });
  modifiedData.authorizations.push({
    name: 'Authorized by',
    value: formValues?.authAuthorizedBy,
  });
  modifiedData.authorizations.push({
    name: 'Authorization number',
    value: formValues?.authAuthorizationNumber,
  });
  modifiedData.authorizations.push({
    name: 'Add\u0027l injury date',
    value: formatDate(formValues?.authAdditionalInjuryDate),
  });
  modifiedData.authorizations.push({
    name: 'Return to Dr. date',
    value: formatDate(formValues?.authReturnToDoctorDate),
  });

  // Building stakeholders section
  modifiedData.stakeholders.push({
    name: 'Adjuster',
    value: !!formValues?.selectAdjuster
      ? formValues?.selectAdjuster?.label || NOVALUE
      : formValues?.adjusterFirstName &&
        formValues?.adjusterLastName &&
        formValues.adjusterCompany
      ? `${formValues?.adjusterFirstName || ''} ${
          formValues?.adjusterLastName || ''
        } - ${formValues?.adjusterCompany || ''}`
      : NOVALUE,
  });
  modifiedData.stakeholders.push({
    name: 'Nurse/Case manager',
    value: !!formValues?.selectCaseManager
      ? formValues?.selectCaseManager?.label || NOVALUE
      : formValues?.caseManagerFirstName &&
        formValues?.caseManagerLastName &&
        formValues.caseManagerCompany
      ? `${formValues?.caseManagerFirstName || ''} ${
          formValues?.caseManagerLastName || ''
        } - ${formValues?.caseManagerCompany || ''}`
      : NOVALUE,
  });

  // Building patient section
  modifiedData.patient.push({
    name: 'Preferred name',
    value: formValues?.patientPreferredName,
  });
  modifiedData.patient.push({
    name: 'Middle name',
    value: formValues?.patientMiddleName,
  });
  modifiedData.patient.push({
    name: 'Suffix',
    value: getSelectNameProperty(
      formValues?.patientSuffix,
      patientNameSuffixChoices
    ),
  });
  modifiedData.patient.push({
    name: 'Address',
    value: formatAddress(
      formValues?.patientAddress,
      formValues?.patientCity,
      formValues?.patientState,
      formValues?.patientZip
    ),
  });
  modifiedData.patient.push({
    name: 'Race',
    value:
      getSelectNameProperty(formValues?.patientRace, raceChoices) ||
      formValues?.patientRace,
  });
  modifiedData.patient.push({
    name: 'Ethnicity',
    value:
      getSelectNameProperty(formValues?.patientEthnicity, ethnicityChoices) ||
      formValues?.patientEthnicity,
  });
  modifiedData.patient.push({
    name: 'Emergency contact',
    value: formValues?.patientEmergencyContactName,
  });
  modifiedData.patient.push({
    name: 'Relationship',
    value: formValues?.patientEmergencyContactRelationship,
  });
  modifiedData.patient.push({
    name: 'Phone',
    // Does this need to be formatted?
    value: formValues?.patientEmergencyContactPhoneNumber,
  });

  // Building other section
  modifiedData.other.push({
    name: 'Preferred clinic',
    value: formValues?.patientPreferredClinic,
  });
  modifiedData.other.push({
    name: 'Preferred therapist',
    value: formValues?.patientPreferredClinician,
  });
  modifiedData.other.push({
    name: 'Other info/notes',
    // Does this need to be formatted?
    value: formValues?.patientOtherInfo,
  });

  return modifiedData;
};
