import { NOVALUE } from "../constants/textConstants";
import moment from "moment";
import { ReferralFormSchema, UserType } from "../types/ReferralFormSchema";

export const formatDate = (date: string | Date | moment.Moment | undefined): string => {
  let dateMoment;

  if (typeof date === 'string') {
    dateMoment = moment(date, 'YYYY-MM-DD');
  } else if (date instanceof Date) {
    dateMoment = moment(date);
  } else if (moment.isMoment(date)) {
    dateMoment = date;
  } else {
    return NOVALUE;
  }

  return dateMoment.format('M/D/YYYY');
};

export const getContactPreference = (
  phonePreferred: boolean | undefined,
  emailPreferred: boolean | undefined
) => {
  if (!!phonePreferred) {
    return 'Phone';
  } else if (!!emailPreferred) {
    return 'Email';
  } else {
    return NOVALUE;
  }
};

export const getSurgicalFormattedNames = (surgicalKey: string | undefined) => {
  switch (surgicalKey) {
    case 'surgical':
      return 'Yes';
    case 'nonsurgical':
      return 'No';
    case 'unknown':
      return 'Unknown';
    default:
      return NOVALUE;
  }
};

export const getSelectNameProperty = (name: string | undefined, source: {name: string; value: string}[] | undefined) => {
  return source?.find(
    (item: { name: string; value: string }) => item.value === name
  )?.name;
};

export const getReferrerName = (formValues: ReferralFormSchema) => {
  if (formValues?.userNewOrExisting === 'new') {
    return formValues?.userFirstName && formValues?.userLastName
      ? `${formValues?.userFirstName} ${formValues?.userLastName} - ${formValues?.userCompany}`
      : NOVALUE;
  } else if (!!formValues?.userAdjuster) {
    return formValues?.userAdjuster?.FirstName ||
      formValues?.userAdjuster?.LastName
      ? `${formValues?.userAdjuster?.FirstName} ${
          formValues?.userAdjuster?.LastName
        } ${formValues?.userAdjuster?.Company ? '-' : ''} ${
          formValues?.userAdjuster?.Company
        }`
      : NOVALUE;
  } else if (!!formValues?.userCaseManager) {
    return formValues?.userCaseManager?.FirstName ||
      formValues?.userCaseManager?.LastName
      ? `${formValues?.userCaseManager?.FirstName} ${
          formValues?.userCaseManager?.LastName
        } ${formValues?.userCaseManager?.Company ? '-' : ''}
        ${formValues?.userCaseManager?.Company}
        `
      : NOVALUE;
  } else if (!!formValues?.userReferringPhysician) {
    return formValues?.userReferringPhysician?.FirstName ||
      formValues?.userReferringPhysician?.LastName
      ? `${formValues?.userReferringPhysician?.FirstName} ${formValues?.userReferringPhysician?.LastName}
      `
      : NOVALUE;
  } else {
    return NOVALUE;
  }
};

export const getReferrerPhone = (formValues: ReferralFormSchema) => {
  if (formValues?.userNewOrExisting === 'new') {
    return formValues?.userPhoneNumber ? formValues?.userPhoneNumber : NOVALUE;
  } else if (!!formValues?.userAdjuster) {
    return NOVALUE;
  } else if (!!formValues?.userCaseManager) {
    if (formValues?.userCaseManager?.WorkPhone) {
      return formValues?.userCaseManager?.WorkPhone;
    } else if (formValues?.userCaseManager?.CellPhone) {
      return formValues?.userCaseManager?.CellPhone;
    } else {
      return NOVALUE;
    }
  } else if (!!formValues?.userReferringPhysician) {
    return formValues?.userReferringPhysician?.Phone
      ? formValues?.userReferringPhysician?.Phone
      : NOVALUE;
  } else {
    return NOVALUE;
  }
};

export const getReferrerEmail = (formValues: ReferralFormSchema) => {
  if (formValues?.userNewOrExisting === 'new') {
    return formValues?.userEmail ? formValues?.userEmail : NOVALUE;
  } else if (!!formValues?.userAdjuster) {
    return NOVALUE;
  } else if (!!formValues?.userCaseManager) {
    return formValues?.userCaseManager?.Email
      ? formValues?.userCaseManager?.Email
      : NOVALUE;
  } else if (!!formValues?.userReferringPhysician) {
    return NOVALUE;
  } else {
    return NOVALUE;
  }
};

export const getCaseType = (caseType: string | undefined) => {
  if (caseType === 'PT') {
    return 'Physical Therapy';
  } else if (caseType === 'OT') {
    return 'Occupational Therapy';
  } else if (caseType === 'WC') {
    return 'Work Conditioning';
  } else if (caseType === 'FC') {
    return 'Functional Capacity Testing';
  } else if (caseType === 'EM') {
    return 'Employment Testing';
  } else if (caseType){
      return caseType
  } else return null;
};

export const getPatientGender = (gender: string | undefined) => {
  if (gender === 'M') {
    return 'Male';
  } else if (gender === 'F') {
    return 'Female';
  } 
  else if (gender){
    return gender
  }
  else return null;
};

export const getTreatingPhysician = (formValues: ReferralFormSchema) => {
  if (!!formValues?.checkBoxForPhysicianInfo) {
    return `${formValues?.physicianFirstName || ''} ${
      formValues?.physicianLastName || ''
    }${(formValues?.physicianPhoneNumber || '') && ' - '}${
      formValues?.physicianPhoneNumber || ''
    }`;
  } else if (!formValues?.checkBoxForPhysicianInfo) {
    return formValues?.treatingPhysician?.label;
  } else {
    return null;
  }
};

export const formatVisitText = (authVisits: string | undefined, times: string | undefined, weeks: string | undefined) => {
  let visitText = authVisits ? authVisits.toString() : '';
  if (times && weeks && parseInt(weeks)) {
    const frequencyText = `${times}/week for ${weeks} week${ parseInt(weeks) > 1 ? 's' : ''}`;
    visitText = visitText + (visitText ? ', ' : '') + frequencyText;
  }
  return visitText || NOVALUE;
};

export const formatAddress = (
  street: string | undefined,
  city: string | undefined,
  state: string | undefined,
  zip: string | undefined,
) => {
  if (!street) {
    return NOVALUE;
  } else {
    return `${street || ''}${street && ', '}${city || ''}${city && ', '}${
      state || ''
    }${state && ' '}${zip || ''}`;
  }
};

 export  const getUserTypeLabel = (userType: UserType | undefined) =>{
    if(userType === UserType.Adjuster){
      return 'Adjuster'
    }
    else if(userType === UserType.CaseManager){
      return 'Case Manager'
    }
    else if(userType === UserType.ReferringDoctor){
      return 'Referring Physician'
    }
    else if(userType === UserType.Other){
      return null
    }
    else return null
  }