import React, { useMemo, useEffect, useState } from 'react';
import styleVars from '../../styleVars';
import { Box, Grid, Typography } from '@material-ui/core';
import { useSearchParams } from 'react-router-dom';

import { fetchForm, parseForm } from '../../helpers/fetchAndLoadForm';
import { parseISO, isValid } from 'date-fns';
import { utcToZonedTime, format } from 'date-fns-tz';

import { CondensedReview } from '../forms/CondensedReview';
const Print = (props: any) => {
  const [formData, setFormData]: any = useState(null);
  const [searchParams] = useSearchParams();
  const referralId = searchParams.get('referralId');
  const claimNumber = searchParams.get('claimNumber');
  const dateOfInjury = searchParams.get('dateOfInjury');

  useEffect(() => {
    async function fetchData() {
      const response = await fetchForm(referralId, claimNumber, dateOfInjury);
      setFormData(response);
    }
    fetchData();
  }, [referralId, claimNumber, dateOfInjury]);

  const parsedFormData: any = useMemo(
    () => formData && parseForm(formData),
    [formData]
  );

  const { formattedDate, formattedTime } = formatDateTime(
    formData?.dateOfReferral
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h5" style={{ color: '#3470A1' }}>
          Your referral confirmation
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        <Box height="24px"></Box>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          style={{ fontSize: styleVars.fontSizeLarge, fontWeight: 400 }}
        >
          {`Please keep this for your records. Your referral for 
         ${parsedFormData?.patientFirstName || ''} ${
            parsedFormData?.patientLastName || ''
          } ${parsedFormData?.patientBirthday ? '-' : ''} ${
            parsedFormData?.patientBirthday
          }
         was received at ${formattedTime} CST on ${formattedDate}. Contact Bardavon at (913) 236-1020 if you have any questions`}
        </Typography>
      </Grid>
      <Grid item sm={8} md={3}></Grid>
      <Grid container spacing={2}>
        <Box height="40px"></Box>
      </Grid>
      <Grid item xs={12} style={{ marginRight: '30px' }}>
        <CondensedReview formValues={parsedFormData} />
      </Grid>
    </Grid>
  );
};

function formatDateTime(isoString: string | undefined) {
  if (!isoString) {
    return { formattedDate: null, formattedTime: null };
  }
  const parsedDate = parseISO(isoString);
  if (!isValid(parsedDate)) {
    return { formattedDate: null, formattedTime: null };
  }

  const timeZone = 'America/Chicago';

  const zonedDate = utcToZonedTime(parsedDate, timeZone);

  const patternDate = 'M/d/yyyy';
  const patternTime = 'h:mm a';

  const formattedDate = format(zonedDate, patternDate, { timeZone });
  const formattedTime = format(zonedDate, patternTime, { timeZone });

  return { formattedDate, formattedTime };
}

export default Print;
