import { ReferralFormSchema, File } from "../types/ReferralFormSchema"
import { NewReferral } from "../types/ReferralSubmissionSchema"
import { MedicalDiagnosisType } from "../types/RequiredInfoTypes"
import {BodyPart} from '../types/ReferralFormSchema'

import {
    momentStripTimestamp, 
    getReferrerType, 
    getFormattedPatientGender, 
    getReferrerId, 
    getContactPreference, 
    getPhoneType, 
    getCaseType, 
    getRace, 
    getMaritalStatus, 
    getEthnicity, 
    getSpecialty, 
    getSurgicalStatus, 
    getEmergencyContactRelationship
} from './submissionFormatters'

type SubmissionFile = {
    upload_id: string, 
    filename: string, 
    document_category: string
}

export const submitReferral = ({
    userFirstName,
    userLastName,
    userEmail,
    userCompany,
    userPhonePreferred,
    userEmailPreferred,
    userPhoneNumber,
    userPhoneType,
    userPhoneExtension,
    userType,
    userAdjuster,
    userReferringPhysician,
    userCaseManager,
    patientFirstName,
    patientLastName,
    patientBirthday,
    patientPhoneNumber,
    patientSex,
    patientPrimaryLanguage,
    interpreterAuthorized,
    transportationAuthorized,
    claimNumber,
    stateOfJury,
    caseType,
    specialty,
    treatingPhysician,
    dateOfInjury,
    checkBoxForPhysicianInfo,
    physicianFirstName,
    physicianLastName,
    physicianEmail,
    physicianPhoneNumber,
    physicianPhoneType,
    physicianPhoneExtension,
    checkBoxPhysicianPhonePreferred,
    checkBoxEmailPreferred,
    medicalDiagnosis,
    bodyPart,
    surgical,
    dateOfSurgery,
    programId,
    programRisk,
    employerSelected,
    jobTitle,
    checkBoxForEmployer,
    employerCompany,
    employerContactName,
    employerAddress,
    employerCity,
    employerState,
    employerZipCode,
    employerEmail,
    checkBoxEmployerEmailPreferred,
    employerPhoneNumber,
    employerPhoneType,
    employerPhoneExtension,
    checkBoxEmployerPhonePreferred,
    authScriptDate,
    authScriptEndDate,
    authAuthorizedVisits,
    authVisitTimes,
    authVisitWeeks,
    authAuthorizedBy,
    authAdditionalInjuryDate,
    authReturnToDoctorDate,
    authAuthorizationNumber,
    selectAdjuster,
    selectCaseManager,
    customCaseManagerInfo,
    customAdjusterInfo,
    patientPreferredName,
    patientMiddleName,
    patientSuffix,
    patientAddress,
    patientCity,
    patientState,
    patientZip,
    patientMaritalStatus,
    patientRace,
    patientEthnicity,
    patientEmergencyContactName,
    patientEmergencyContactRelationship,
    patientEmergencyContactPhoneNumber,
    patientPreferredClinic,
    patientPreferredClinician,
    patientOtherInfo,
    adjusterFirstName,
    adjusterLastName,
    adjusterEmail,
    adjusterCompany,
    adjusterPhonePreferred,
    adjusterEmailPreferred,
    adjusterPhoneNumber,
    adjusterPhoneType,
    adjusterPhoneExtension,
    caseManagerFirstName,
    caseManagerLastName,
    caseManagerEmail,
    caseManagerCompany,
    caseManagerPhonePreferred,
    caseManagerEmailPreferred,
    caseManagerPhoneNumber,
    caseManagerPhoneType,
    caseManagerPhoneExtension,
    uploadFiles,
    userRoleInCase,
    userNewOrExisting,
}: ReferralFormSchema) => {
    const newReferral: NewReferral = {
            addLInjuryDate: momentStripTimestamp(authAdditionalInjuryDate),
            adjuster: selectAdjuster?.id,
            adjusterCompany: customAdjusterInfo ? adjusterCompany : null,
            adjusterEmail: customAdjusterInfo ? adjusterEmail : null, 
            adjusterFax: null, // Not captured
            adjusterFirstName: customAdjusterInfo ? adjusterFirstName : null,
            adjusterLastName: customAdjusterInfo ? adjusterLastName : null,
            adjusterPhone: customAdjusterInfo && adjusterPhoneNumber ? adjusterPhoneNumber : null,
            adjusterPreferredContact: customAdjusterInfo ? getContactPreference(adjusterPhonePreferred, adjusterEmailPreferred) : null,
            adjusterPhoneType: customAdjusterInfo && adjusterPhoneType ? getPhoneType(adjusterPhoneType) : null,
            adjusterPhoneExtension: customAdjusterInfo && adjusterPhoneExtension ? adjusterPhoneExtension : null,
            adjusterSameAsReferrer: userType === 'adjuster',
            authorizedNoOfVisits: Number(authAuthorizedVisits),
            authorizationNumber: authAuthorizationNumber,
            bodyParts: bodyPart?.length 
            ? bodyPart.reduce((accumulator: string, currentBodyPart: BodyPart, index: number, arr: BodyPart[]) => {
                const delimiter = (index < arr.length - 1) ? ';' : '';
                return accumulator + currentBodyPart.BodyPartName + delimiter; // other property is bodyPartName
            }, '')
            : null,
            caseManager: selectCaseManager?.id || null,
            caseManagerPhoneType: customCaseManagerInfo ? getPhoneType(caseManagerPhoneType) : null,
            caseManagerPreferredContact: customCaseManagerInfo ? getContactPreference(caseManagerPhonePreferred, caseManagerEmailPreferred) : null,
            caseType: getCaseType(caseType),
            claimDateOfInjury: momentStripTimestamp(dateOfInjury),
            claimNumber: claimNumber,
            claimStateOfJurisdiction: stateOfJury || null,
            client: null, // Not captured
            dateOfReferral: null,
            duration: Number(authVisitWeeks),
            emergencyContactName: patientEmergencyContactName,
            emergencyContactPhoneNumber: patientEmergencyContactPhoneNumber,
            employeeWorkSchedule: null, // Not captured
            employeeWorkStatusTimeOfReferral: null, // Not captured,
            employerLocation: employerSelected?.id || null,
            employerLocationCity: checkBoxForEmployer ? employerCity : null,
            employerLocationCompany: checkBoxForEmployer ? employerCompany : null,
            employerLocationContactName: checkBoxForEmployer ? employerContactName : null,
            employerLocationEmail: checkBoxForEmployer ? employerEmail : null,
            employerLocationPhoneNumber: checkBoxForEmployer && employerPhoneNumber ? employerPhoneNumber : null,
            employerLocationPhoneType: checkBoxForEmployer ? getPhoneType(employerPhoneType) : null,
            employerLocationPreferredContact: checkBoxForEmployer ? getContactPreference(checkBoxEmployerPhonePreferred, checkBoxEmployerEmailPreferred) : null,
            employerLocationPhoneExtension: checkBoxForEmployer ? employerPhoneExtension : null,
            employerLocationState: checkBoxForEmployer ? employerState || null : null,
            employerLocationStreetAddress: checkBoxForEmployer ? employerAddress || null : null,
            employerLocationZipPostalCode: checkBoxForEmployer ? employerZipCode : null,
            ethnicity: getEthnicity(patientEthnicity),
            fileUploads: uploadFiles?.length ? uploadFiles?.map((f: File) => ({upload_id: f.fileId, filename: f.name, document_category: f.category})) as SubmissionFile[] : [],
            frequency: Number(authVisitTimes),
            healthcareDiagnosis: medicalDiagnosis?.length ? medicalDiagnosis?.reduce((accumulator: string, currentDiagnosis: MedicalDiagnosisType, index: number, arr: MedicalDiagnosisType[]) => {
                const delimiter = (index < arr.length - 1) ? ';' : '';
                return accumulator + currentDiagnosis.diagnosisCode + delimiter;
              }, '') : null,
            maritalStatus: getMaritalStatus(patientMaritalStatus),
            medicalDiagnosis: medicalDiagnosis,
            nurseCaseManagerCompany: customCaseManagerInfo ? caseManagerCompany : null,
            nurseCaseManagerEmail: customCaseManagerInfo ? caseManagerEmail : null,
            nurseCaseManagerFax: null, // Not captured,
            nurseCaseManagerFirstName: customCaseManagerInfo ? caseManagerFirstName : null,
            nurseCaseManagerLastName: customCaseManagerInfo ? caseManagerLastName: null,
            nurseCaseManagerPhone: customCaseManagerInfo && caseManagerPhoneNumber ? caseManagerPhoneNumber : null,
            nurseCaseManagerPhoneExt: customCaseManagerInfo && caseManagerPhoneExtension ? caseManagerPhoneExtension : null,
            nurseCaseManagerSameAsReferrer: userType === 'caseManager',
            otherSpecialInstructions: patientOtherInfo,
            patientAddressLine: patientAddress,
            patientAddressLine2: null, // Not captured,
            patientCity: patientCity,
            patientDob: momentStripTimestamp(patientBirthday),
            patientFirstName: patientFirstName,
            patientGender: getFormattedPatientGender(patientSex || null),
            patientJobTitle: jobTitle,
            patientLastName: patientLastName,
            patientMiddleName: patientMiddleName,
            patientPhone: patientPhoneNumber,
            // patientPreferredLanguage: patientPreferredLanguage, // NEED TO CLARIFY THIS
            patientPreferredLanguage: patientPrimaryLanguage ? patientPrimaryLanguage : null, // label and value were made the same for this property
            patientPreferredName: patientPreferredName,
            patientState: patientState ? patientState : null,
            patientSuffix: patientSuffix || null,
            patientZip: patientZip,
            physician: checkBoxForPhysicianInfo ? null : treatingPhysician?.id || null,
            program: programId || null,
            programRisk: programRisk || null,
            race: getRace(patientRace),
            referralAuthorizedBy: authAuthorizedBy || null,
            referralMethod: 'Web Form',
            referrer: getReferrerId(userType, userAdjuster?.SalesforceId, userCaseManager?.SalesforceId, userReferringPhysician?.SalesforceId),
            referrerCompany: userNewOrExisting === 'new' && userType !== 'referringDoctor' ? userCompany : null,
            referrerEmail: userNewOrExisting === 'new' ? userEmail : null,
            referrerFax: null , // Not collected
            referrerFirstName: userNewOrExisting === 'new' ? userFirstName : null,
            referrerJobTitle: userNewOrExisting === 'new' && userType === 'other' ? userRoleInCase || null : null,
            referrerLastName: userNewOrExisting === 'new' ? userLastName : null,
            referrerPhone: userNewOrExisting === 'new' ? userPhoneNumber : null,
            referrerPhoneType: userNewOrExisting === 'new' ? getPhoneType(userPhoneType) : null,
            referrerType: getReferrerType(userType) || null,
            referringPhoneExtension: userNewOrExisting === 'new' ? userPhoneExtension : null,
            referringPhysicianFirstName: checkBoxForPhysicianInfo ? physicianFirstName : null,
            referringPhysicianLastName: checkBoxForPhysicianInfo ? physicianLastName : null,
            referringPhysicianPhone: checkBoxForPhysicianInfo ? physicianPhoneNumber || null : null,
            referringPhysicianPhoneExt: checkBoxForPhysicianInfo ? physicianPhoneExtension : null,
            referrerPreferredContact: userNewOrExisting === 'new' ? getContactPreference(userPhonePreferred, userEmailPreferred) : null,
            referringPhysicianEmail: checkBoxForPhysicianInfo ? physicianEmail : null,
            referringPhysicianPhoneType: checkBoxForPhysicianInfo ? getPhoneType(physicianPhoneType) : null,
            referringPhysicianPreferredContact: checkBoxForPhysicianInfo ? getContactPreference(checkBoxPhysicianPhonePreferred, checkBoxEmailPreferred) : null,
            relationshipToPatient: getEmergencyContactRelationship(patientEmergencyContactRelationship),
            returnToDoctorDate: momentStripTimestamp(authReturnToDoctorDate),
            requestedTherapyProviderClinician: patientPreferredClinician,
            requestedTherapyProviderLocation: patientPreferredClinic,
            scriptDate: momentStripTimestamp(authScriptDate),
            authorizationEndDate: momentStripTimestamp(authScriptEndDate),
            specialty: getSpecialty(specialty),
            surgicalStatus: getSurgicalStatus(surgical),
            surgeryDate: momentStripTimestamp(dateOfSurgery),
            translationRequired: interpreterAuthorized,
            transportation: transportationAuthorized,
    }

    Object.keys(newReferral).forEach((key: string) => {
      const objKey  = key as keyof NewReferral
      let tempValue = newReferral[objKey]

      if (typeof tempValue === 'string' || tempValue instanceof String) {
        tempValue = tempValue.trim()

        if(tempValue === '') {
          tempValue = null
        }

        if(key.toLowerCase()){
          assign(newReferral, objKey, tempValue)
        }
      }
    })

    return fetch(
        `${process.env.REACT_APP_REFERRAL_BACKEND}/public/referral/new`,
        {
            method: 'POST',
            body: JSON.stringify(newReferral),
            headers: {
                "Content-Type": "application/json",
              },
        }
      )
        .then((response) => {
          if (response.status === 409) {
            return;
          }
          if (!response.ok) {
            throw new Error(JSON.stringify(response.status));
          }
          return response.json();
        })
        .then((data) => data)
        .catch((error) => {
            console.warn("submitReferral: error occured.", error)
            throw new Error(error)
        });
}

const assign = <T extends object, K extends keyof T>(obj: T, key: K, val: T[K]) => {
  obj[key] = val;
}