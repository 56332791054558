import { Employer, Physician } from '../types/ReferralFormSchema'
import { AdjusterDTO, CaseManagerDTO, PhysicianDTO } from '../components/inputs/UserSelection/UserSelectionAPI'
import { ReferralFormSchema } from "../types/ReferralFormSchema"
import { NewReferral } from "../types/ReferralSubmissionSchema"


export const fetchForm = (referralId: string | null, claimNumber: string | null, dateOfInjury: string | null): any => {

  const url = `${process.env.REACT_APP_REFERRAL_BACKEND}/public/referral/summary?referral_id=${referralId}&claim_number=${claimNumber}&claim_date_of_injury=${dateOfInjury}`

  return fetch(
    url,
    { method: 'GET' }
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error(JSON.stringify(response.status));
      }
      return response.json();
    })
    .then((data) => data as NewReferral)
    .catch((error) => {
      console.error(error);
      return null;
    })
}

// TODO: This code desperately needs cleanup. - DLH
export const parseForm = (
  {
    addLInjuryDate,
    adjuster,
    adjusterCompany,
    adjusterEmail,
    adjusterFax,
    adjusterFirstName,
    adjusterLastName,
    adjusterPhone,
    adjusterPhoneExtension,
    adjusterPhoneType,
    adjusterPreferredContact,
    adjusterSameAsReferrer,
    attorney,
    attorneyEmail,
    attorneyFax,
    attorneyFirstName,
    attorneyLastName,
    attorneyPhone,
    attorneyPhoneExtension,
    attorneyPhoneType,
    attorneyPreferredContact,
    authorizationNumber,
    authorizedNoOfVisits,
    bodyParts,
    caseManager,
    caseManagerPhoneType,
    caseManagerPreferredContact,
    caseType,
    claimDateOfInjury,
    claimNumber,
    claimStateOfJurisdiction,
    client,
    dateOfReferral,
    duration,
    emergencyContactName,
    emergencyContactPhoneNumber,
    employeeWorkSchedule,
    employeeWorkStatusTimeOfReferral,
    employerLocation,
    employerLocationCity,
    employerLocationCompany,
    employerLocationContactName,
    employerLocationEmail,
    employerLocationPhoneExtension,
    employerLocationPhoneNumber,
    employerLocationPhoneType,
    employerLocationPreferredContact,
    employerLocationState,
    employerLocationStreetAddress,
    employerLocationZipPostalCode,
    externalReferralId,
    ethnicity,
    frequency,
    maritalStatus,
    medicalDiagnosis,
    healthcareDiagnosis,
    nurseCaseManagerCompany,
    nurseCaseManagerEmail,
    nurseCaseManagerFax,
    nurseCaseManagerFirstName,
    nurseCaseManagerLastName,
    nurseCaseManagerPhone,
    nurseCaseManagerPhoneExt,
    nurseCaseManagerSameAsReferrer,
    otherSpecialInstructions,
    patientAddressLine,
    patientAddressLine2,
    patientCity,
    patientDob,
    patientFirstName,
    patientGender,
    patientJobTitle,
    patientLastName,
    patientMiddleName,
    patientPhone,
    patientPreferredLanguage,
    patientPreferredName,
    patientState,
    patientSuffix,
    patientZip,
    physician,
    hasProgram,
    programId,
    programRisk,
    race,
    referralAuthorizedBy,
    referralMethod,
    referral,
    referrer,
    referrerCompany,
    referrerEmail,
    referrerFax,
    referrerFirstName,
    referrerJobTitle,
    referrerLastName,
    referrerPhone,
    referrerPhoneExtension,
    referrerPhoneType,
    referrerPreferredContact,
    referrerType,
    referringPhysicianEmail,
    referringPhysicianFirstName,
    referringPhysicianLastName,
    referringPhysicianPhone,
    referringPhysicianPhoneExt,
    referringPhysicianPhoneType,
    referringPhysicianPreferredContact,
    relationshipToPatient,
    requestedTherapyProviderClinician,
    requestedTherapyProviderLocation,
    returnToDoctorDate,
    scriptDate,
    authorizationEndDate,
    specialty,
    surgicalStatus,
    surgeryDate,
    translationAuthorized,
    translationRequired,
    transportation,
    transportationAuthorized,
    hasattachments,
    attachmentcount,
    referralId,
    fileUploads,
    saveOnly,
  }
    : any): ReferralFormSchema => {
  const adjusterData = adjusterSameAsReferrer && !adjuster ?
    {
      adjuster: referrer,
      adjusterCompany: referrerCompany,
      adjusterEmail: referrerEmail,
      adjusterFirstName: referrerFirstName,
      adjusterLastName: referrerLastName,
      adjusterPhoneNumber: referrerPhone,
      adjusterPhonePreferred: referrerPhoneType === 'Phone',
      adjusterEmailPreferred: referrerPhoneType === 'Email',
      adjusterPhoneType: referrerPhoneType,
      adjusterPhoneExtension: referrerPhoneExtension
    } : {
      adjuster: adjuster,
      adjusterCompany: adjusterCompany,
      adjusterEmail: adjusterEmail,
      adjusterFirstName: adjusterFirstName,
      adjusterLastName: adjusterLastName,
      adjusterPhoneNumber: adjusterPhone,
      adjusterPhonePreferred: adjusterPreferredContact === 'Phone',
      adjusterEmailPreferred: adjusterPreferredContact === 'Email',
      adjusterPhoneType: adjusterPhoneType,
      adjusterPhoneExtension: adjusterPhoneExtension,
    }

  const caseManagerData = nurseCaseManagerSameAsReferrer && !caseManager ?
    {
      caseManager: caseManager,
      caseManagerCompany: referrerCompany,
      caseManagerEmail: referrerEmail,
      caseManagerFirstName: referrerFirstName,
      caseManagerLastName: referrerLastName,
      caseManagerPhoneNumber: referrerPhone,
      caseManagerPhoneExtension: referrerPhoneExtension,
      caseManagerPhoneType: referrerPhoneType,
      caseManagerPhonePreferred: referrerPhoneType === 'Phone',
      caseManagerEmailPreferred: referrerPhoneType === 'Email',
    } :
    {
      caseManager: caseManager,
      caseManagerCompany: nurseCaseManagerCompany,
      caseManagerEmail: nurseCaseManagerEmail,
      caseManagerFirstName: nurseCaseManagerFirstName,
      caseManagerLastName: nurseCaseManagerLastName,
      caseManagerPhoneNumber: nurseCaseManagerPhone,
      caseManagerPhoneExtension: nurseCaseManagerPhoneExt,
      caseManagerPhoneType: caseManagerPhoneType,
      caseManagerPhonePreferred: caseManagerPreferredContact === 'Phone',
      caseManagerEmailPreferred: caseManagerPreferredContact === 'Email',
    }

  const selectAdjuster = getSelectAdjusterData(adjusterData)
  const selectCaseManager = getSelectCaseManagerData(caseManagerData)
  const selectPhysician = getPhysicianData(referringPhysicianPhone, referringPhysicianFirstName, referringPhysicianLastName, physician) // treatingPhysician
  const employerSelected = getEmployerData(employerLocationCompany, employerLocation)

  const userAdjuster: any = getUserAdjusterData(adjusterData) || null
  const userCaseManager: any = getUserCaseManagerData(caseManagerData) || null
  const userReferringPhysician = getUserPhysicianData(referringPhysicianFirstName as string, referringPhysicianLastName as string, physician as string)

  const formData: ReferralFormSchema = {
    userNewOrExisting: 'new',
    authAdditionalInjuryDate: addLInjuryDate || undefined,
    selectAdjuster: selectAdjuster,
    adjusterCompany: adjusterData.adjusterCompany,
    adjusterEmail: adjusterData.adjusterEmail,
    adjusterFirstName: adjusterData.adjusterFirstName,
    adjusterLastName: adjusterData.adjusterLastName,
    adjusterPhoneNumber: adjusterData.adjusterPhoneNumber,
    adjusterPhonePreferred: adjusterData.adjusterPhonePreferred,
    adjusterEmailPreferred: adjusterData.adjusterEmailPreferred,
    adjusterPhoneType: adjusterData.adjusterPhoneType,
    adjusterPhoneExtension: adjusterData.adjusterPhoneExtension,
    userType: loadReferrerType(referrerType as string) as any,
    authAuthorizedVisits: authorizedNoOfVisits ? String(authorizedNoOfVisits) : '',
    authVisitTimes: frequency ? String(frequency) : '',
    authVisitWeeks: duration ? String(duration) : '',
    authAuthorizationNumber: authorizationNumber,

    bodyPart: (bodyParts ? (bodyParts.includes(';') ? bodyParts.split(';') : [bodyParts]) : [])
      .map((b: string): any => ({
        BodyPartId: b,
        BodyPartName: b,
      })),
    dateOfReferral: dateOfReferral,
    selectCaseManager: selectCaseManager,
    caseManagerPhoneType: caseManagerData.caseManagerPhoneType,
    caseManagerPhonePreferred: caseManagerData.caseManagerPhonePreferred,
    caseManagerEmailPreferred: caseManagerData.caseManagerEmailPreferred,
    caseType: caseType || undefined,
    dateOfInjury: claimDateOfInjury || undefined,
    claimNumber: claimNumber,
    stateOfJury: claimStateOfJurisdiction || undefined,
    patientEmergencyContactName: emergencyContactName,
    patientEmergencyContactPhoneNumber: emergencyContactPhoneNumber,
    employerSelected: employerSelected,
    employerCity: employerLocationCity || undefined,
    employerCompany: employerLocationCompany || undefined,
    employerContactName: employerLocationContactName || undefined,
    employerEmail: employerLocationEmail || undefined,
    employerPhoneNumber: employerLocationPhoneNumber || undefined,
    employerPhoneType: employerLocationPhoneType || undefined,
    checkBoxEmployerEmailPreferred: employerLocationPreferredContact === 'Email',
    checkBoxEmployerPhonePreferred: employerLocationPreferredContact === 'Phone',
    employerPhoneExtension: employerLocationPhoneExtension || undefined,
    employerState: employerLocationState || undefined,
    employerAddress: employerLocationStreetAddress || undefined,
    employerZipCode: employerLocationZipPostalCode || undefined,
    patientEthnicity: ethnicity || undefined,
    uploadFiles: [],
    medicalDiagnosis: medicalDiagnosis,
    patientMaritalStatus: maritalStatus || undefined,
    caseManagerCompany: caseManagerData.caseManagerCompany,
    caseManagerEmail: caseManagerData.caseManagerEmail,
    caseManagerFirstName: caseManagerData.caseManagerFirstName,
    caseManagerLastName: caseManagerData.caseManagerLastName,
    caseManagerPhoneNumber: caseManagerData.caseManagerPhoneNumber,
    caseManagerPhoneExtension: caseManagerData.caseManagerPhoneExtension,
    patientOtherInfo: otherSpecialInstructions,
    patientAddress: patientAddressLine,
    patientCity: patientCity,
    patientBirthday: patientDob || undefined,
    patientFirstName: patientFirstName,
    patientSex: patientGender || undefined,
    jobTitle: patientJobTitle,
    patientLastName: patientLastName,
    patientMiddleName: patientMiddleName,
    patientPhoneNumber: patientPhone,
    patientPrimaryLanguage: patientPreferredLanguage || undefined,
    patientPreferredName: patientPreferredName,
    patientState: patientState || undefined,
    patientSuffix: patientSuffix || undefined,
    patientZip: patientZip,
    treatingPhysician: selectPhysician,
    patientRace: race || undefined,
    authAuthorizedBy: referralAuthorizedBy || undefined,
    userAdjuster: referrerType === 'Adjuster' ? userAdjuster : null,
    userCaseManager: referrerType === 'Case manager' ? userCaseManager : null,
    userReferringPhysician: referrerType === 'Referring doctor' ? userReferringPhysician as any : null,
    userCompany: referrerCompany || undefined,
    userEmail: referrerEmail || undefined,
    userFirstName: referrerFirstName || undefined,
    userRoleInCase: referrerJobTitle || undefined,
    userLastName: referrerLastName || undefined,
    userPhoneNumber: referrerPhone || undefined,
    userPhoneType: referrerPhoneType || undefined,
    userPhoneExtension: referrerPhoneExtension || undefined,
    physicianFirstName: referringPhysicianFirstName || undefined,
    physicianLastName: referringPhysicianLastName || undefined,
    physicianPhoneNumber: referringPhysicianPhone || undefined,
    physicianPhoneExtension: referringPhysicianPhoneExt || undefined,
    userPhonePreferred: referrerPreferredContact === 'Phone',
    userEmailPreferred: referrerPreferredContact === 'Email',
    physicianEmail: referringPhysicianEmail || undefined,
    physicianPhoneType: referringPhysicianPhoneType || undefined,
    checkBoxPhysicianPhonePreferred: referringPhysicianPreferredContact === 'Phone',
    checkBoxEmailPreferred: referringPhysicianPreferredContact === 'Email',
    patientEmergencyContactRelationship: relationshipToPatient || undefined,
    authReturnToDoctorDate: returnToDoctorDate || undefined,
    patientPreferredClinician: requestedTherapyProviderClinician,
    patientPreferredClinic: requestedTherapyProviderLocation,
    authScriptDate: scriptDate || undefined,
    authScriptEndDate: authorizationEndDate || undefined,
    specialty: specialty || undefined,
    surgical: cleanSurgical(surgicalStatus),
    dateOfSurgery: surgeryDate || undefined,
    hasProgram: hasProgram,
    programId: programId,
    programRisk: programRisk,
    interpreterAuthorized: translationRequired,
    transportationAuthorized: transportation,
  }

  return formData
}


function cleanSurgical(surgicalStatus: string) : string {
  return surgicalStatus ? surgicalStatus.toLowerCase().replace('-', '') : 'unknown'
}

export const loadReferrerType = (referrerType: string | null | undefined) => {
  if (referrerType === 'Adjuster') {
    return 'adjuster'
  }
  else if (referrerType === 'Case manager') {
    return 'caseManager'
  }
  else if (referrerType === 'Referring doctor') {
    return 'referringDoctor'
  }
  else if (referrerType === 'Other') {
    return 'other'
  }
  else return null
}

const getSelectAdjusterData = (adjusterData: any): any => {
  return {
    FirstName: adjusterData.adjusterFirstName,
    LastName: adjusterData.adjusterLastName,
    Email: adjusterData.adjusterEmail,
    Company: adjusterData.adjusterCompany,
    SalesforceId: adjusterData.adjuster,
    id: adjusterData.adjuster,
    label: adjusterData.adjusterFirstName &&
      adjusterData.adjusterLastName &&
      adjusterData.adjusterCompany
      ? `${adjusterData.adjusterFirstName || ''} ${adjusterData.adjusterLastName || ''
      } - ${adjusterData.adjusterCompany || ''}`
      : null
  }
}

const getSelectCaseManagerData = (caseManagerData: any): any => {
  return {
    FirstName: caseManagerData.caseManagerFirstName,
    LastName: caseManagerData.caseManagerLastName,
    Company: caseManagerData.caseManagerCompany,
    SalesforceId: String(caseManagerData.caseManager),
    WorkPhone: caseManagerData.caseManagerPhone,
    CellPhone: caseManagerData.caseManagerPhone,
    Email: caseManagerData.caseManagerEmail,
    id: String(caseManagerData.caseManagerId),
    label: caseManagerData.caseManagerFirstName &&
      caseManagerData.caseManagerLastName &&
      caseManagerData.caseManagerCompany
      ? `${caseManagerData.caseManagerFirstName || ''} ${caseManagerData.caseManagerLastName || ''
      } - ${caseManagerData.caseManagerCompany || ''}`
      : null
  }
}

const getPhysicianData = (physicianPhoneNumber: string, physicianFirstName: string, physicianLastName: string, physicianId: string): Physician => {
  return {
    Fax: '',
    NPI: '',
    Phone: physicianPhoneNumber,
    FirstName: physicianFirstName,
    LastName: physicianLastName,
    SalesforceId: physicianId,
    bNotesReferringId: '',
    id: physicianId,
    label: `${physicianFirstName || ''} ${physicianLastName || ''} ${physicianPhoneNumber ? '-' : ''} ${physicianPhoneNumber || ''}`
  }
}
const getUserAdjusterData = (adjusterData: any): AdjusterDTO => {
  return {
    FirstName: adjusterData.adjusterFirstName,
    LastName: adjusterData.adjusterLastName,
    Email: adjusterData.adjusterEmail,
    Company: adjusterData.adjusterCompany,
    SalesforceId: adjusterData.adjuster,
  }
}

const getUserCaseManagerData = (caseManagerData: any): CaseManagerDTO => {
  return {
    FirstName: caseManagerData.caseManagerFirstName,
    LastName: caseManagerData.caseManagerLastName,
    Company: caseManagerData.caseManagerCompany,
    SalesforceId: String(caseManagerData.caseManager),
    WorkPhone: caseManagerData.caseManagerPhone,
    CellPhone: caseManagerData.caseManagerPhone,
    Email: caseManagerData.caseManagerEmail,
    bNotesCaseManagerId: 1,
  }
}

const getUserPhysicianData = (physicianFirstName: string, physicianLastName: string, physicianId: string): PhysicianDTO => {
  return {
    FirstName: physicianFirstName,
    LastName: physicianLastName,
    SalesforceId: physicianId,
    Company: '',
    Email: '',
    bNotesCaseManagerId: 1,
  }
}

const getEmployerData = (employerCompany: string, employerId: string): Employer => {
  return {
    label: employerCompany,
    id: employerId,
    Name: employerCompany,
    EmployerID: employerId,
    JobAnalysisPresent: '',
  }
}